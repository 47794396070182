import PageComponent from '../component/page-component';


class PreviewsSliderNavigation extends PageComponent {

	constructor({
		root,
		element,
		navigationAttribute = 'goTo',
	}) {
		super({root: root, element: element});
		this.navigationAttribute = navigationAttribute;
		this.resizeDebounceTimer = null;
		this.touchOffsetX = null;
		this.swipeLength = 75;
		this.currentViewportSize = window.innerWidth;
	}


	prepare() {
		const id = this.dataAttr().get('for', '');
		this.slider = this.components.queryComponent(this.root, this.dataSelector('id', id));
		if (this.slider) {
			// add intersection observer vor in view animation
			const buttons = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute));
			buttons.forEach(button => {
				const dir = this.dataAttr(button).get(this.navigationAttribute);
				if (dir === 'next' || dir === 'prev') {
					const observer = new IntersectionObserver(entries => {
						button.classList.toggle( 'js-inView', entries[0].isIntersecting );
					});
					observer.observe(button);
				}
			});
			this.listeners.click = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'click', this.onClick.bind(this));
			this.listeners.drag = this.events.on(this.slider.getElement(), 'dragstart', this.onDrag.bind(this));
			this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
			this.listeners.touchStart = this.events.on(this.slider.getElement(), 'touchstart', this.onTouchStart.bind(this));
			this.listeners.touchMove = this.events.on(this.slider.getElement(), 'touchmove', this.onTouchMove.bind(this));
			this.listeners.touchEnd = this.events.on(this.slider.getElement(), 'touchend', this.onTouchEnd.bind(this));
			this.listeners.move = this.events.on(this.slider.getElement(), 'slider:move', this.onMove.bind(this));

		}
		this.updateButtons();
	}

	onResize(e) {
		this.enlarging = (window.innerWidth - this.currentViewportSize) > 0;
		this.currentViewportSize = window.innerWidth;
		if (this.enlarging) {
			// when enlarging, reset the index after debounce delay
			clearTimeout(this.resizeDebounceTimer2);
			this.resizeDebounceTimer2 = setTimeout(() => this.resetIndex(), 500);
		}
		clearTimeout(this.resizeDebounceTimer);
		this.resizeDebounceTimer = setTimeout(() => this.updateButtons(), 200);
	}
	resetIndex() {
		// when slider is maxed out to the right, move it back to not leave empty items
		if (!this.slider.hasNext()) {
			this.slider.setIndex(Math.max(0, this.slider.amount - this.slider.getSlideSize()));
			this.updateButtons();
		}
	}

	onClick(event, target) {
		const direction = this.dataAttr(target).get(this.navigationAttribute);
		this.slider[direction]();
		this.updateButtons();
	}
	onDrag(event) {
		event.preventDefault();
		return false;
	}
	onSwipe(event, target) {
		if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT) {
			this.slider.prev();
		}
		if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_LEFT) {
			this.slider.next();
		}
		window.getSelection().removeAllRanges(); // get rid of selected text
		this.updateButtons();
	}
	onTouchStart(event, target) {
		this.touchOffsetX = event.targetTouches[0].clientX;
	}
	onTouchMove(event, target) {
		if (this.touchOffsetX === null) return;

		const dragged = event.targetTouches[0].clientX - this.touchOffsetX;
		if (dragged > this.swipeLength) {
			this.slider.prev();
			this.touchOffsetX = null;
			this.updateButtons();
		}
		if (dragged < -1 * this.swipeLength) {
			this.slider.next();
			this.touchOffsetX = null;
			this.updateButtons();
		}
	}
	onTouchEnd(event, target) {
		this.touchOffsetX = null;
	}
	onMove(event, target) {
		this.updateButtons();
	}

	updateButtons() {
		const buttons = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute));
		buttons.forEach(button => {
			const dir = this.dataAttr(button).get(this.navigationAttribute);
			if (dir === 'next') this.classList(button).toggle('disabled', !this.slider.hasNext());
			if (dir === 'prev') this.classList(button).toggle('disabled', !this.slider.hasPrev());
		});
		this.element.style.display = this.slider.getSlideSize() >= this.slider.amount ? 'none' : 'block';
	}


}


export default PreviewsSliderNavigation;

var classof = require(52);
var ITERATOR = require(129)('iterator');
var Iterators = require(84);
module.exports = require(58).isIterable = function (it) {
  var O = Object(it);
  return O[ITERATOR] !== undefined
    || '@@iterator' in O
    // eslint-disable-next-line no-prototype-builtins
    || Iterators.hasOwnProperty(classof(O));
};

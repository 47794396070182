import PageComponent from '../../common/component/page-component';

class RevealMore extends PageComponent {

	constructor({
					root,
					element
				}) {
		super({root: root, element: element});
		this.open = false;
		this.itemCount = 999;
	}


	prepare() {
		this.itemCount = this.element.childElementCount;
    }
	onClick() {
		this.toggle();
	}

	showLess() {
		this.element.classList.add('ubf-js-active');
	}
	toggle() {
		this.element.classList.toggle('ubf-js-active');
	}
}

export default RevealMore;

'use strict';
var global = require(69);
var core = require(58);
var dP = require(91);
var DESCRIPTORS = require(62);
var SPECIES = require(129)('species');

module.exports = function (KEY) {
  var C = typeof core[KEY] == 'function' ? core[KEY] : global[KEY];
  if (DESCRIPTORS && C && !C[SPECIES]) dP.f(C, SPECIES, {
    configurable: true,
    get: function () { return this; }
  });
};

'use strict';
// https://github.com/tc39/proposal-promise-try
var $export = require(66);
var newPromiseCapability = require(88);
var perform = require(103);

$export($export.S, 'Promise', { 'try': function (callbackfn) {
  var promiseCapability = newPromiseCapability.f(this);
  var result = perform(callbackfn);
  (result.e ? promiseCapability.reject : promiseCapability.resolve)(result.v);
  return promiseCapability.promise;
} });

import PageComponent from '../../common/component/page-component';

class RevealMoreToggle extends PageComponent {

	constructor({
					root,
					element
				}) {
		super({root: root, element: element});
		this.for = null;
		this.open = false;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.for = data.for;
		this.openLabelElement = 'Reveal more';
		this.closeLabelElement = 'Reveal more';

		this.revealMoreContainer = this.components.queryComponent(this.root, this.dataSelector('id', this.for));

		if (this.revealMoreContainer.itemCount > 7) {
			this.element.classList.add('ubf-js-active');
			this.showLess();
			this.listeners.revealMore = this.events.on(this.element, 'click', this.onClick.bind(this));
		}

	}

	onClick() {
		this.toggle();
	}

	showLess() {
		this.revealMoreContainer.showLess();
		this.element.classList.add('ubf-js-less');
	}
	toggle() {
		this.revealMoreContainer.toggle();
		this.element.classList.toggle('ubf-js-less');
	}
}

export default RevealMoreToggle;

import PageComponent from '../component/page-component';


class PreviewsSlider extends PageComponent {

	constructor({
					root,
					element,
					indexVar = 'sliderItemIndex',
					slideSizeVar = 'itemsPerSlide'
				}) {
		super({root: root, element: element});
		this.indexVar = '--' + indexVar;
		this.slideSizeVar = '--' + slideSizeVar;
		this.amount = 0;
		this.randomize = false;
	}


	prepare() {
		this.amount = this.element.children.length;

		const data = this.dataAttr().getAll();
		this.randomize = data.randomize;

		if (this.randomize) {
			const children = this.element.children;
			for (const child of children) {
				child.style.order = Math.floor(Math.random() * 100); // Generate a random order value between 0 and 99
			}
		}
	}

	prev() {
		this.move(-1);
	}


	next() {
		this.move(1);
	}

	hasPrev() {
		return this.getIndex() > 0;
	}
	hasNext() {
		return this.getIndex() + this.getSlideSize() <  this.amount;
	}

	move(offset) {
		if (offset === 0) {
			return;
		}
		// get the sign only
		offset = (offset / Math.abs(offset));
		const currentIndex = this.getIndex();
		const size = 1; // this.getSlideSize(); always move by one item, not by page
		const newIndex = Math.max(0, Math.min(this.amount - 1, currentIndex + (size * offset)));
		if (currentIndex !== newIndex && (offset === -1 || newIndex - currentIndex === size)) {
			this.setIndex(newIndex);
		}
	}


	getIndex() {
		const style = getComputedStyle(this.element);
		let currentIndex = style.getPropertyValue(this.indexVar);
		if (currentIndex === '') {
			currentIndex = 0;
		}
		return parseInt(currentIndex, 10);
	}


	setIndex(value) {
		this.element.style.setProperty(this.indexVar, value);
		this.events.trigger(this.element, 'slider:move', {component: this});
	}


	getSlideSize() {
		const style = getComputedStyle(this.element);
		let size = style.getPropertyValue(this.slideSizeVar);
		if (size === '') {
			size = 1;
		}
		return parseInt(size, 10);
	}



}


export default PreviewsSlider;
